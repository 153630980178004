html {
  overflow: auto;
  overflow-x: hidden
}

body {
  height: 100%;
  width: 100%;
  padding-bottom: 16px;
  padding-top: 16px;
}

div#root {
}

div#app {
}

.simplebar-content-wrapper {
  overflow: hidden !important;
}

@font-face {
  font-family: "AccordAlt";
  src: url("/fonts/AccordAlt-Medium.woff2") format("woff2"),
    url("/fonts/AccordAlt-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "AccordAlt";
  src: url("/fonts/AccordAlt-Regular.woff2") format("woff2"),
    url("/fonts/AccordAlt-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AccordAlt";
  src: url("/fonts/AccordAlt-ExtraBold.woff2") format("woff2"),
    url("/fonts/AccordAlt-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "AccordAlt";
  src: url("/fonts/AccordAlt-Light.woff2") format("woff2"),
    url("/fonts/AccordAlt-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "AccordAlt";
  src: url("/fonts/AccordAlt-Bold.woff2") format("woff2"),
    url("/fonts/AccordAlt-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "AccordAlt";
  src: url("/fonts/AccordAlt-ExtraLight.woff2") format("woff2"),
    url("/fonts/AccordAlt-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 202, 110, 0.3);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 202, 110, 0.5);
  background-color: rgba(0, 202, 110);
}
